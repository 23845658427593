import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import { toast } from "react-toastify";
import { Formik } from "formik";
import moment from "moment";
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import {
    Box, Grid, Button, LinearProgress, Paper, FormLabel, RadioGroup, FormControlLabel,
    Radio, TextField, Stack, Divider, Modal,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText, InputAdornment
} from '@mui/material';
import Typography from "@mui/material/Typography";
import AddProduct from "./AddProduct";
import { Item } from "../../components/Common/UiComponents";

function CouponFormComponent(props) {
    const [productList, setProductList] = useState(props.productList || []);
    const [selectedProductList, setSelectedProductList] = useState([]);
    const saveSelProductList = (selectedProds) => {
        setSelectedProductList(selectedProds)
    }

    useEffect(() => {
        if (!(productList && productList.length > 0)) {
            axios
                .get(`${BASE_URL}/getallproducts`)
                .then(response => setProductList(response.data))
                .catch(error => console.error(error));
        }
    }, []);

    return (<>
        <Box sx={{ flexGrow: 1 }} style={{ margin: '5px 12.5px' }}>

            {/* <Grid container spacing={2} direction={'column'} justifyContent={'center'} alignItems={'stretch'} > */}
            {/* <Grid item>
                    Coupon Details
                </Grid> */}
            {/* <Grid item > */}
            <Formik
                key={`CouponDetailForm`}
                isInitialValid={false}
                initialValues={{
                    discountType:"Coupon",
                    couponType: "value",
                    userType: "all",
                    showProducts : 'no',
                    couponUsage: "one",
                    couponGenerationType: "multiple",
                    minCartValue: "",
                    discountValue: "",
                    maxDiscountValue: "",
                    maxRedeemCount: "",
                    couponCode: "",
                    companyName:"",
                    couponPrefix: "",
                    noOfDigits: "",
                    noOfCoupons: "",
                    email: "",
                    fromDateTime: moment().startOf('day'),
                    toDateTime: moment().endOf('day'),
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.companyName) {
                        errors.companyName = "* Company name is required."
                    }
                    if(values.discountType == "Coupon")
                    {
                        if (!values.minCartValue) {
                            errors.minCartValue = "* Minimum cart value required."
                        } else if (isNaN(values.minCartValue)) {
                            errors.minCartValue = "* Minimum cart value should be number."
                        }
                    }

                    if (values.maxDiscountValue > values.discountValue) {
                        errors.maxDiscountValue = "* Max discount should be less than discount amount."
                    }

                    if (values.couponType === 'percentage' && values.discountValue > 100) {
                        errors.discountValue = "* discount value cannot exceed 100%";
                    }

                    if (!values.fromDateTime) {
                        errors.fromDateTime = "* From date time required.";
                    } else if (!values.toDateTime) {
                        errors.toDateTime = "* To date time required."
                    } else if (values.fromDateTime.unix() > values.toDateTime.unix()) {
                        errors.toDateTime = "* To date time should be greater than From date time."
                    }

                    if (values.couponGenerationType === 'multiple') {
                        if (!values.couponPrefix) {
                            errors.couponPrefix = "* Coupon prefix required."
                        }
                        if (!values.noOfDigits) {
                            errors.noOfDigits = "* No of digits required."
                        }
                        if (!values.noOfCoupons) {
                            errors.noOfCoupons = "* no of Coupons required."
                        }
                    }
                    if (values.couponGenerationType === 'single' && !values.couponCode) {
                        errors.couponCode = "* Coupon code required."
                    }

                    if (values.userType === 'single' && !values.email) {
                        errors.email = "* User email ID required."
                    }
                    //todo: do email regex validation

                    // userType=all and couponUsage=multi -> require | all user multi use  require redemm unique/not unique user 1000
                    // usertype=single and couponUsage=single -> oneTime | single user single time redeem
                    // usertype=single and couponUsage=multi ->require |single user multi time redem 
                    // usertype=all and couponUsage=single ->not require redeemtion | all user single time no redeem unique/not unique
                    // usertype= new user-> no redeemtion
                    // productt array =0 elements -> not required | total valu coupon no reddemtion


                    if (values.couponUsage === 'multi' && !values.maxRedeemCount) {
                        errors.maxRedeemCount = "* Max redeemtion count is required."
                    }



                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    // console.log('submitting', values);
                    // toast.success("Testing...");
                    try {
                        const {
                            discountType,
                            couponType,
                            userType,
                            showProducts,
                            couponUsage,
                            companyName,
                            couponGenerationType,
                            minCartValue,
                            discountValue,
                            maxDiscountValue,
                            maxRedeemCount,
                            couponCode,
                            couponPrefix,
                            noOfDigits,
                            noOfCoupons,
                            email,
                            fromDateTime,
                            toDateTime
                        } = values;
                        const selectedProdList = selectedProductList && selectedProductList.length > 0 ? selectedProductList.map(x => ({
                            code: x.PID,
                            asins:x.asins,
                            name: x.product_name,
                            value: x.prodDiscVal || 0
                        })) : [];
                        const requestBody = {
                            discountType,
                            couponType,
                            userType,
                            // couponUsage: (values.userType !== 'new' && selectedProductList.length > 0) ? couponUsage : undefined,
                            couponUsage: (values.userType !== 'new') ? couponUsage : undefined,
                            companyName,
                            couponGenerationType,
                            minCartValue,
                            discountValue,
                            maxDiscountValue: maxDiscountValue || undefined,
                            // maxRedeemCount: (values.userType !== 'new' && selectedProductList.length > 0 && couponUsage === 'multi') ? maxRedeemCount || undefined : undefined,
                            maxRedeemCount: (values.userType !== 'new' && couponUsage === 'multi') ? maxRedeemCount || undefined : undefined,
                            couponCode,
                            couponPrefix: couponPrefix || undefined,
                            noOfDigits: noOfDigits || undefined,
                            noOfCoupons: noOfCoupons || undefined,
                            emailId: email || undefined,
                            startDate: fromDateTime.unix(),
                            endDate: toDateTime.unix(),
                            selectedProdList
                        }
                        const res = await axios({
                            url: `${BASE_URL}/api/coupon/generate`,
                            method: 'post',
                            headers: {
                                'Authorization': JSON.parse(localStorage.getItem('authUser')).token
                            },
                            data: requestBody
                        });
                        if (res && res.data) {
                            if (res.data.isError) {
                                return toast.error(res.data.msg);
                            } else {
                                resetForm();
                                toast.success("Record inserted succesfully.");
                                await props.couponFormParentSubmitAction();
                                return;
                            }
                        } else {
                            console.error('/api/coupon/generate :: Exception', res); //todo: remove after testing
                            throw "Invalid response."
                        }
                    }
                    catch (error) {
                        console.error('/api/coupon/generate :: Exception', error);
                        return toast.error("Server error. Please try after sometimes.")
                    }

                }}
            >
                {
                    ({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            {isSubmitting && <LinearProgress />}
                            <Stack direction={'column'}>
                            <Item>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item md={values.couponUsage === "multi" ? 6 : 12}>
                                                    <FormLabel id='cdform-couponUsage-radio-group-label'>Discount Type</FormLabel>

                                                    <RadioGroup row
                                                        aria-labelledby="cdform-couponUsage-radio-group-label"
                                                        name="cdform-couponUsage-radio-group"
                                                        value={values.discountType}
                                                        onChange={(event) => setFieldValue('discountType', event.currentTarget.value, true)}
                                                    >
                                                        <FormControlLabel value="Coupon" control={<Radio />} label="Coupon" />
                                                        <FormControlLabel value="Gift Voucher" control={<Radio />} label="Gift Voucher" />
                                                    </RadioGroup>
                                                </Grid>
                                             </Grid>
                                        </Item>
                                <Item>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item md={values.userType === 'single' ? 6 : 12}>
                                            <FormLabel id='cdform-userType-radio-group-label'>User Type</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="cdform-userType-radio-group-label"
                                                name="cdform-userType-radio-group"
                                                value={values.userType}
                                                onChange={(event) => setFieldValue('userType', event.currentTarget.value, true)}
                                            >
                                                <FormControlLabel value="all" control={<Radio />} label="All User" />
                                                <FormControlLabel value="single" control={<Radio />} label="Single User" />
                                                <FormControlLabel value="new" control={<Radio />} label="New User" />
                                            </RadioGroup>
                                        </Grid>
                                        {values.userType === 'single' ? <Grid item md={6}>
                                            {/* <FormLabel id='cdform-email-tf-label'>Enter User Email ID</FormLabel> */}
                                            <TextField
                                                fullWidth
                                                // aria-labelledby="cdform-email-tf-label"
                                                label='User Email ID'
                                                id="email"
                                                name="email"
                                                type="text"
                                                placeholder="Enter User Email ID"
                                                variant="outlined"
                                                autoComplete="off"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                error={errors.email && touched.email ? true : false}
                                                helperText={errors.email && touched.email ? <span style={{ color: 'red' }}>{`${errors.email}`}</span> : null}
                                            />
                                        </Grid> : null}

                                    </Grid>
                                </Item>
                                {/* Add Date Range */}
                                <Item>
                                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item md={6}>
                                            <MobileDateTimePicker
                                                sx={{ width: '100%' }}
                                                label="From Date Time"
                                                value={values.fromDateTime}
                                                defaultValue={moment().startOf('day')}
                                                minDate={moment()}
                                                onChange={(value, context) => {
                                                    // console.log('fromDateTime', value)
                                                    setFieldValue('fromDateTime', value, true)
                                                }}
                                            />
                                            {errors.fromDateTime && touched.fromDateTime ? <span style={{ color: 'red' }}>{`${errors.fromDateTime}`}</span> : null}


                                        </Grid>
                                        <Grid item md={6}>
                                            <MobileDateTimePicker
                                                sx={{ width: '100%' }}
                                                label="To Date Time"
                                                value={values.toDateTime}
                                                defaultValue={moment().endOf('day')}
                                                minDate={moment()}
                                                onChange={(value, context) => {
                                                    // console.log(value.clone().format('YYYY-MM-DD HH:mm:ss') + 'toDateTime', value)
                                                    setFieldValue('toDateTime', value, true)
                                                }}
                                            />
                                            {errors.toDateTime && touched.toDateTime ? <span style={{ color: 'red' }}>{`${errors.toDateTime}`}</span> : null}

                                        </Grid>
                                    </Grid>
                                </Item>

                                <Item>
                                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item md={6}>
                                        {/* <FormLabel id='cdform-userType-radio-group-label'>Company Name</FormLabel> */}
                                        <TextField
                                                    fullWidth
                                                    // aria-labelledby="cdform-couponCode-tf-label"
                                                    label='Company Name'
                                                    id="companyName"
                                                    name="companyName"
                                                    type="text"
                                                    placeholder="Enter Company Name"
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.companyName}
                                                    error={errors.companyName && touched.companyName ? true : false}
                                                    helperText={errors.companyName && touched.companyName ? <span style={{ color: 'red' }}>{`${errors.companyName}`}</span> : null}
                                                />
                                            


                                        </Grid>
                                       
                                    </Grid>
                                </Item>



                                <Item>
                                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item md={12}>
                                            <FormLabel id='cdform-couponType-radio-group-label'>Coupon Type</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="cdform-couponType-radio-group-label"
                                                name="cdform-couponType-radio-group"
                                                value={values.couponType}
                                                onChange={(event) => {
                                                    setSelectedProductList([]);
                                                    return setFieldValue('couponType', event.currentTarget.value, true)
                                                }}
                                            >
                                                <FormControlLabel value="value" control={<Radio />} label="Value" />
                                                <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                                            </RadioGroup>
                                            {/* <Divider sx={{ bgcolor: "black" }}/> */}
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                label={'Minimum Cart Value'}
                                                id="minCartValue"
                                                name="minCartValue"
                                                type="number"
                                                placeholder="Enter Minimum Cart Value"
                                                variant="outlined"
                                                autoComplete="off"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.minCartValue}
                                                error={errors.minCartValue && touched.minCartValue ? true : false}
                                                helperText={errors.minCartValue && touched.minCartValue ? <span style={{ color: 'red' }}>{`${errors.minCartValue}`}</span> : null}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                label='Discount Value'
                                                id="discountValue"
                                                name="discountValue"
                                                type="number"
                                                placeholder="Enter Discount Value"
                                                variant="outlined"
                                                autoComplete="off"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                // onChange={(event) => {
                                                //     return setFieldValue('dicountValue', event.currentTarget.value, true)
                                                // }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={
                                                    values.couponType === 'value' ? { startAdornment: <InputAdornment position="start">₹</InputAdornment> } :
                                                        { endAdornment: <InputAdornment position="end">%</InputAdornment> }
                                                }
                                                value={values.discountValue}
                                                error={errors.discountValue && touched.discountValue ? true : false}
                                                helperText={errors.discountValue && touched.discountValue ? <span style={{ color: 'red' }}>{`${errors.discountValue}`}</span> : null}
                                            />
                                        </Grid>

                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                label='Maximum Discount Value'
                                                id='maxDiscountValue'
                                                name='maxDiscountValue'
                                                type="number"
                                                placeholder="Enter Maximum Discount Value"
                                                variant="outlined"
                                                autoComplete="off"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.maxDiscountValue}
                                                error={errors.maxDiscountValue && touched.maxDiscountValue ? true : false}
                                                helperText={errors.maxDiscountValue && touched.maxDiscountValue ? <span style={{ color: 'red' }}>{`${errors.maxDiscountValue}`}</span> : null}
                                            />
                                        </Grid>

                                    </Grid>
                                </Item>
                                {/* Add Product */}
                                <Item>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item md={values.showProducts === 'no' ? 6 : 12}>
                                            <FormLabel id='cdform-userType-radio-group-label'>Show Products List</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="cdform-userType-radio-group-label"
                                                name="cdform-userType-radio-group"
                                                value={values.showProducts}
                                                onChange={(event) => setFieldValue('showProducts', event.currentTarget.value, true)}
                                            >
                                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="no" control={<Radio />} label="No" />

                                            </RadioGroup>
                                        </Grid>
                                    

                                    </Grid>
                                    {
                                        values.showProducts === "yes" ? <Grid item md={6}>
                                            <AddProduct
                                                couponType={values.couponType}
                                                discountValue={values.discountValue}
                                                productList={productList}
                                                selectedProductList={selectedProductList || []}
                                                saveSelProductList={saveSelProductList}
                                            />
                                        </Grid> : null
                                    }

                                </Item>

                                {
                                    // (values.userType !== 'new' && selectedProductList.length > 0) ?
                                    (values.userType !== 'new') ?
                                        <Item>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item md={values.couponUsage === "multi" ? 6 : 12}>
                                                    <FormLabel id='cdform-couponUsage-radio-group-label'>Coupon Usage</FormLabel>

                                                    <RadioGroup row
                                                        aria-labelledby="cdform-couponUsage-radio-group-label"
                                                        name="cdform-couponUsage-radio-group"
                                                        value={values.couponUsage}
                                                        onChange={(event) => setFieldValue('couponUsage', event.currentTarget.value, true)}
                                                    >
                                                        <FormControlLabel value="one" control={<Radio />} label="One Time" />
                                                        <FormControlLabel value="multi" control={<Radio />} label="Multi Time" />
                                                    </RadioGroup>
                                                </Grid>
                                                {
                                                    values.couponUsage === "multi" ? <Grid item md={6}>
                                                        {/* <FormLabel id='cdform-maxRedeemCount-tf-label'>Enter Maximum Redeemtion Count</FormLabel> */}
                                                        <TextField
                                                            fullWidth
                                                            label='Maximum Redemption Count'
                                                            id="maxRedeemCount"
                                                            name="maxRedeemCount"
                                                            type="number"
                                                            placeholder="Enter Maximum Redeemtion Count"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.maxRedeemCount}
                                                            error={errors.maxRedeemCount && touched.maxRedeemCount ? true : false}
                                                            helperText={errors.maxRedeemCount && touched.maxRedeemCount ? <span style={{ color: 'red' }}>{`${errors.maxRedeemCount}`}</span> : null}
                                                        />

                                                    </Grid> : null
                                                }
                                            </Grid>
                                        </Item>
                                        : null}




                                <Item>
                                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item md={values.couponGenerationType === 'single' ? 6 : 12}>
                                            <FormLabel id='cdform-couponGenerationType-radio-group-label'>Coupon Generation Type</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="cdform-couponGenerationType-radio-group-label"
                                                name="cdform-couponGenerationType-radio-group-label"
                                                value={values.couponGenerationType}
                                                onChange={(event) => setFieldValue('couponGenerationType', event.currentTarget.value, true)}
                                            >
                                                <FormControlLabel value="multiple" control={<Radio />} label="Multiple Coupon" />
                                                <FormControlLabel value="single" control={<Radio />} label="Single Coupon" />
                                            </RadioGroup>
                                        </Grid>
                                        {
                                            values.couponGenerationType === 'single' ? <Grid item>
                                                {/* <FormLabel id='cdform-couponCode-tf-label'>Enter Coupon Code</FormLabel> */}
                                                <TextField
                                                    fullWidth
                                                    // aria-labelledby="cdform-couponCode-tf-label"
                                                    label='Coupon Code'
                                                    id="couponCode"
                                                    name="couponCode"
                                                    type="text"
                                                    placeholder="Enter Coupon Code"
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.couponCode}
                                                    error={errors.couponCode && touched.couponCode ? true : false}
                                                    helperText={errors.couponCode && touched.couponCode ? <span style={{ color: 'red' }}>{`${errors.couponCode}`}</span> : null}
                                                />
                                            </Grid> : <>
                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        label='Coupon Prefix'
                                                        id="couponPrefix"
                                                        name="couponPrefix"
                                                        type="text"
                                                        placeholder="Enter Coupon Prefix"
                                                        variant="outlined"
                                                        autoComplete="off"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.couponPrefix}
                                                        error={errors.couponPrefix && touched.couponPrefix ? true : false}
                                                        helperText={errors.couponPrefix && touched.couponPrefix ? <span style={{ color: 'red' }}>{`${errors.couponPrefix}`}</span> : null}
                                                    />

                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        label='Number of Digits'
                                                        id="noOfDigits"
                                                        name="noOfDigits"
                                                        type="number"
                                                        placeholder="Enter Number of Digits"
                                                        variant="outlined"
                                                        autoComplete="off"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.noOfDigits}
                                                        error={errors.noOfDigits && touched.noOfDigits ? true : false}
                                                        helperText={errors.noOfDigits && touched.noOfDigits ? <span style={{ color: 'red' }}>{`${errors.noOfDigits}`}</span> : null}
                                                    />

                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        label='Number of Coupons'
                                                        id="noOfCoupons"
                                                        name="noOfCoupons"
                                                        type="number"
                                                        placeholder="Enter Number of Coupons"
                                                        variant="outlined"
                                                        autoComplete="off"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.noOfCoupons}
                                                        error={errors.noOfCoupons && touched.noOfCoupons ? true : false}
                                                        helperText={errors.noOfCoupons && touched.noOfCoupons ? <span style={{ color: 'red' }}>{`${errors.noOfCoupons}`}</span> : null}
                                                    />

                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Item>



                                <Item>
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <Button
                                                type='submit'
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Item>



                            </Stack>
                        </form>
                    )
                }

            </Formik>
            {/* </Grid> */}


            {/* </Grid> */}
        </Box>


    </>)
}

export default CouponFormComponent;