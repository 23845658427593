import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import Select from "react-select";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import "./auth.css";
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "", idx: 1, user: {}, selectedOptions: [] };
  }

  componentDidMount() {
    this.fetchAdminDetails();
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid });
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid });
      }
      setTimeout(() => {
        this.props.resetProfileFlag();
      }, 3000);
    }
  }

  fetchAdminDetails = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("data: ", user);
    this.setState({ user: user });
    this.setState({
      selectedOptions: user.activitiesAccess.map(item => {
        return {
          value: item,
          label: item,
        };
      }),
    });
  };

  render() {
    const options = [
      { value: "User Management", label: "User Management" },
      { value: "Tag Management", label: "Tag Management" },
      { value: "LNF Management", label: "LNF Management" },
      { value: "Return Management", label: "Return Management" },
      {
        value: "MAC Address Pairing Management",
        label: "MAC Address Pairing Management",
      },
      { value: "Coupon Management", label: "Coupon Management" },
      {
        value: "Tag Generation Management",
        label: "Tag Generation Management",
      },
    ];
    const SelectField = ({ options, field, form }) => {
      return (
        <Select
          options={options}
          isMulti
          name={field.name}
          value={this.state.selectedOptions}
          // onChange={option => {
          //   setSelectedOptions(option);
          //   const values = option.map(item => item.value);
          //   form.setFieldValue(field.name, values);
          //   setSelectedAccessPerminssion(values);
          // }}
          // onBlur={() => form.setFieldTouched(field.name, true)}
          isDisabled
        />
      );
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Setting" breadcrumbItem="Profile" />

            {/* <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{this.state.name}</h5>
                          <p className="mb-1">tech@tag8.in</p>
                          <p className="mb-0">Id no: #{this.state.idx}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}

            {/* <h4 className="card-title mb-4">Change User Name</h4> */}

            <Card className="user-profile-view-card">
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    firstName: this.state.user.name || "",
                    lastName: this.state.user.lastName || "",
                    email: this.state.user.email || "",
                    // password: (this.state.user.password) || "",
                    phone: this.state.user.phone || "",
                    countryCode: this.state.user.countryCode || "",
                    accessPermission: this.state.user.accessPermission || [],
                  }}
                  onSubmit={async values => {}}
                >
                  {({ errors, status, touched }) => (
                    <Form>
                      <Row>
                        <Col className="col-12">
                          <>
                            <div className="mb-3">
                              <Label className="form-label">First Name</Label>
                              <Field
                                name="firstName"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.firstName && touched.firstName
                                    ? " is-invalid"
                                    : "")
                                }
                                readOnly
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Last Name</Label>
                              <Field
                                name="lastName"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.lastName && touched.lastName
                                    ? " is-invalid"
                                    : "")
                                }
                                readOnly
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Phone</Label>
                              <MuiPhoneNumber
                                placeholder="Phone"
                                name="phone"
                                className={
                                  "form-control" +
                                  (errors.phone && touched.phone
                                    ? " is-invalid"
                                    : "")
                                }
                                defaultCountry={"in"}
                                value={`${this.state.user.phoneCode} ${this.state.user.phone}`}
                                // onChange={value => {
                                //   const code = value?.split(" ")[0];
                                //   setUserPhoneCountryCode(code);
                                //   setUserPhoneNo(
                                //     value?.slice(code?.length).trim()
                                //   );
                                // }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3 select-div">
                              <Label className="form-label">
                                Access Permission
                              </Label>
                              <Field
                                component={SelectField}
                                name="accessPermission"
                                options={options}
                                readOnly
                              />
                              <ErrorMessage
                                name="accessPermission"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </>

                          {/* email */}
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              readOnly
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/* {!isEditing ? (
                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <Field
                                name="password"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          ) : null} */}
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row> */}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(UserProfile)
);
