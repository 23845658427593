import * as React from "react";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { YAxis } from "recharts";
import { height } from "@mui/system";
import axiosInstance from "Axiosinstance";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { ThemeProvider, createTheme } from "@mui/system";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IndeterminateCheckBox } from "@mui/icons-material";
import "./visualization.css";

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

async function getData(year, month) {
  const { data } = await axiosInstance.get(
    `api/crm/getTagActivated?year=${year}&month=${month}`
  );
  return data;
}
async function getDispatchedActiveTagsData(year, month, customer) {
  const { data } = await axiosInstance.post(
    `api/crm/getactivatedtagsbydispatch?year=${year}&month=${month}&customer=${customer}`
  );
  return data;
}

async function getTagsCountCurrentYear() {
  const { data } = await axiosInstance.post(`/api/crm/gettagscountcurrentyear`);
  return data;
}
const chartSetting = {
  yAxis: [
    {
      label: "Tag Activation",
    },
  ],
  width: 700,
  height: 300,
  // sx: {
  //     [`.${axisClasses.left} .${axisClasses.label}`]: {
  //         transform: 'translate(-20px, 0)',
  //     },
  // }
};

// Years
const years = Array.from(
  { length: new Date().getFullYear() - 2021 + 1 },
  (_, i) => 2021 + i
);
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const customers = ["HDFC", "AMAZON"];
// const days = [
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
//   23, 24, 25, 26, 27, 28, 29, 30,
// ];

const Visualization = () => {
  //meta title
  document.title = "Data Visualization";

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(0);
  const [days, setDays] = React.useState([]);
  const [tagsCount, setTagsCount] = React.useState({
    tagActivatedToday: 0,
    tagActivatedThisYear: 0,
  });

  const [tableData, setTableData] = useState([]);

  const [dispatchYear, setDispatchYear] = useState("");
  const [dispatchMonth, setDispatchMonth] = useState("");
  const [dispatchCustomer, setDispatchCustomer] = useState("");
  const [dispatchTableData, setDispatchTableData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const resData = await getTagsCountCurrentYear();
        // console.log("resData: ", resData);
        setTagsCount({
          tagActivatedToday: resData.totalTagsToday,
          tagActivatedThisYear: resData.totalTagsThisYear,
        });
      } catch (e) {
        console.log(
          "Error::getTagsCountCurrentYear: Error while fetching the activated tags count!"
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resData = await getData(year, month);
        // console.log(resData);
        setData(resData.data);
        setTableData(resData.productCategoryWiseTagsCount);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(
          "Error::getData: Error while fetching the year wise activated tags count!"
        );
      }
    })();
  }, [year, month]);
  useEffect(() => {
    if (dispatchYear && dispatchMonth !== undefined && dispatchCustomer)
      (async () => {
        try {
          setLoading(true);
          const resData = await getDispatchedActiveTagsData(
            dispatchYear,
            dispatchMonth,
            dispatchCustomer
          );
          // console.log(resData);
          setDispatchTableData(resData.dispatchedActiveTags);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(
            "Error::getData: Error while fetching the dispatched active tags!"
          );
        }
      })();
  }, [dispatchYear, dispatchMonth, dispatchCustomer]);
  const handleChangeYear = (event) => {
    setYear(event.target.value);
    setMonth(0);
  };
  const handleChangeMonth = (event) => {
    setMonth(event.target.value);

    const selectedMonth = event.target.value;
    const daysInMonth = new Date(year, parseInt(selectedMonth), 0).getDate();
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    setDays(daysArray);
  };
  const handleChangeDispatchYear = (event) => {
    setDispatchYear(event.target.value);
    setDispatchMonth("");
    setDispatchCustomer("");
  };
  const handleChangeDispatchMonth = (event) => {
    setDispatchMonth(event.target.value);
  };
  const handleChangeDispatchCustomer = (event) => {
    setDispatchCustomer(event.target.value);
  };
  return (
    <Box sx={{ flexGrow: 1, mt: 15, ml: 5, mb: "5rem" }}>
      <Grid container spacing={4}>
        {/* Card Grid */}
        <Grid xs={4}>
          <Card sx={{ maxWidth: 200, backgroundColor: "#ffea00" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Total Activations Today
              </Typography>
              <Typography variant="h5" component="div">
                {tagsCount.tagActivatedToday}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={4}>
          <Card sx={{ maxWidth: 200, backgroundColor: "#ff9100" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Activations Current Year
              </Typography>
              <Typography variant="h5" component="div">
                {tagsCount.tagActivatedThisYear}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={4}></Grid>

        {/* Select Grid */}
        <Typography sx={{ fontSize: 16 }} color="black" gutterBottom>
          The data shown in the Bar Graph (A) and Table (A) below are based on
          the selected year & month.
        </Typography>
        <Grid xs={11} style={{ display: "flex", gap: "1.5rem" }}>
          {/* Year select */}
          <Item style={{ width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select year of Tag Activation
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Select year of Tag Activation"
                onChange={handleChangeYear}
              >
                {years.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          {/* Month Select */}
          <Item style={{ width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Month of Tag Activation
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Select year of Tag Activation"
                onChange={handleChangeMonth}
              >
                <MenuItem value={0}>ALL</MenuItem>
                {months.map((value, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid>

        {/* Bar Grid */}
        <Grid xs={8}>
          <Item>
            <BarChart
              series={[{ data: data }]}
              height={290}
              xAxis={[
                {
                  data: month === 0 ? months : days,
                  scaleType: "band",
                },
              ]}
              margin={{ top: 5, bottom: 30, left: 40, right: 10 }}
              {...chartSetting}
            />
          </Item>
          <Typography
            align="center"
            sx={{ fontSize: 16 }}
            color="black"
            gutterBottom
          >
            Bar Graph (A)
          </Typography>
        </Grid>
        {/* <Grid xs={4}></Grid> */}

        {/* Table */}
        <Grid xs={5} style={{ marginBottom: "0rem" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Product Category Details
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    No of Activation
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {data._id}
                    </TableCell>
                    <TableCell align="right">{data.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            align="center"
            sx={{ fontSize: 16 }}
            color="black"
            gutterBottom
          >
            Table (A)
          </Typography>
        </Grid>
        <Grid xs={7}></Grid>

        <div className="divider"></div>
        {/* Dispatch  */}
        <Grid xs={12}>
          <Typography sx={{ fontSize: 16 }} color="black" gutterBottom>
            Kindly select the dispatch date and customer name to view the
            corresponding data. The information will be displayed in Table (B)
            below, based on the selected dispatch details.
          </Typography>
        </Grid>
        {/* Dispatch Select Option */}
        <Grid xs={11} style={{ display: "flex", gap: "1.5rem" }}>
          {/* Year select */}
          <Item style={{ width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select year of Dispatched
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dispatchYear}
                label="Select year of Dispatched"
                onChange={handleChangeDispatchYear}
              >
                {years.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          {/* Month Select */}
          <Item style={{ width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Month of Dispatched
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dispatchMonth}
                label="Select Month of Dispatched"
                onChange={handleChangeDispatchMonth}
              >
                <MenuItem value={0}>ALL</MenuItem>
                {months.map((value, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          {/* Customer Select */}
          <Item style={{ width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Customer
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dispatchCustomer}
                label="Select year of Tag Activation"
                onChange={handleChangeDispatchCustomer}
              >
                <MenuItem value={"All"}>ALL</MenuItem>
                {customers.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid>
        {/* Table */}
        {dispatchTableData === null ? null : (
          <Grid xs={5} style={{ marginBottom: "0rem" }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Product Category Details
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      No of Activation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dispatchTableData.map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {data._id}
                      </TableCell>
                      <TableCell align="right">{data.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              align="center"
              sx={{ fontSize: 16 }}
              color="black"
              gutterBottom
            >
              Table (B)
            </Typography>
          </Grid>
        )}
        {/* <Grid xs={7}></Grid> */}
      </Grid>

      {/* LOADER */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </Box>
  );
};

export default Visualization;
