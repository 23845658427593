import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Row, Col } from "reactstrap";
import axiosInstance from "Axiosinstance";
import { toast } from "react-toastify";
import convertDataToExcel from "data-to-xlsx";

// Api Funciton
const fetchInsuranceDownloadableData = async (year, month, company) => {
  try {
    const pageType = "Billing Page";
    const { data } = await axiosInstance.post(
      `/api/getinsurancebyfilter?year=${year}&month=${month}&company=${company}&pageType=${pageType}`,
      {
        filter: "Downloadable",
      }
    );
    return data;
  } catch (error) {
    console.log("fetchInsuranceDownloadbleData::", error);
  }
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = Array.from(
  { length: new Date().getFullYear() - 2024 + 1 },
  (v, i) => 2024 + i
);
const companies = ["Tata_aig"];

const Billing = () => {
  const [loading, setLoading] = useState(false);
  const [monthsArray, setMonthsArray] = useState(months);
  const [downloadInsuranceState, setDownloadInsuranceState] = useState({
    year: "",
    month: "",
    company: "",
  });

  const generateerror = (err) => {
    toast.error(err, {
      position: "top-center",
    });
  };
  const generateSuccess = (msg) => {
    toast.success(msg, {
      position: "top-center",
    });
  };

  const changeHandleDownloadModal = () => {
    setDownloadInsuranceState((prevState) => ({
      ...prevState,
      year: "",
      month: "",
      company: "",
    }));
  };

  const changeHandleInsurance = (event) => {
    const { name, value } = event.target;
    setDownloadInsuranceState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const submitHandleDownloadInsurance = async (event) => {
    try {
      event.preventDefault();
      const { year, month, company } = downloadInsuranceState;
      if (year && month !== undefined && company) {
        setLoading(true);
        const data = await fetchInsuranceDownloadableData(year, month, company);

        if (!data.isError) {
          convertDataToExcel({
            data: data.insurance,
            fileName: `Billing_Report_${months[month]}_${year}`,
            isBordered: true,
          });
          // console.log("data: ", data);
          setLoading(false);
          changeHandleDownloadModal();
          generateSuccess("Billing excel sheet downloaded successfully.");
        } else {
          setLoading(false);
          generateerror(data.message);
        }
      } else {
        generateerror("Please Select All Fields!");
      }
    } catch (error) {
      console.log("submitHandleDownloadInsurance::Error:: ", error);
      generateerror("Error Accured while downloading the Billing Excel Sheet!");
    }
  };

  useEffect(() => {
    const currYear = new Date();
    if (currYear.getFullYear() === downloadInsuranceState.year) {
      setMonthsArray(months.slice(0, currYear.getMonth() + 1));
    } else {
      setMonthsArray(months);
    }
  }, [downloadInsuranceState.year]);

  document.title = "Billing Report";
  return (
    <div className="page-content">
      <h3 style={{ marginBottom: "2rem", textAlign: "center" }}>
        Billing Report Download
      </h3>
      {/* Download Insurance Details Modal */}
      <Row>
        <Col sm={3}></Col>
        <Col sm={6}>
          <form onSubmit={submitHandleDownloadInsurance}>
            <div>
              <div>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={downloadInsuranceState.year}
                    label="Year"
                    name="year"
                    onChange={changeHandleInsurance}
                  >
                    {years.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={downloadInsuranceState.month}
                    label="Month"
                    name="month"
                    onChange={changeHandleInsurance}
                  >
                    {monthsArray.map((val, index) => (
                      <MenuItem key={index} value={index}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ marginTop: "1rem" }}>
                <FormControl fullWidth>
                  <InputLabel>Company</InputLabel>
                  <Select
                    value={downloadInsuranceState.company}
                    label="Company"
                    name="company"
                    onChange={changeHandleInsurance}
                  >
                    {companies.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div style={{ marginTop: "2rem", textAlign: "center" }}>
              <button
                type="submit"
                className="btn btn-success save-user"
                style={{ width: "60%" }}
              >
                Download
              </button>
            </div>
          </form>
        </Col>
        <Col sm={3}></Col>
      </Row>

      {/* Loader */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Billing;
