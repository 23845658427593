import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Container,
  Input,
  Alert,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";
// import CouponForm from "../CouponFrom";
// import CouponFormComponent from "../";
// import "../Coupons/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import * as XLSX from "xlsx";
// import { utils, write, writeFileSync } from "xlsx";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { BASE_URL } from "../../config";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DateTimePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import { get } from "lodash";
// const columns = [
//   {
//     dataField: "id",
//     text: "ID",
//     sort: true,
//   },
//   {
//     dataField: "name",
//     text: "Name",
//     sort: true,
//   },
//   {
//     dataField: "email",
//     text: "Email",
//     sort: true,
//   },
//   // add more columns as needed
// ];
function TagGeneration() {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const token = authUser ? authUser.token : null;
  const [tagInitial, setTagInitial] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [count, setCount] = useState(0);
  const [valuableType, setValuableType] = useState("");
  const [numOfDigits, setNumOfDigits] = useState(4);
  const [isNumOfDigitsDisabled, setIsNumOfDigitsDisabled] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tagnumber, setTagNumber] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [modalOpen, setModalOpenState] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [numError, setNumError] = useState("");
  const [possiblity, setPossibility] = useState("");
  const [updatepossiblity, setupdatePossibility] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [numOfDigitsMap, setNumOfDigitsMap] = useState("");
  const [tagGeneration, setTagGeneration] = useState("");
  const [timerComplete, setTimerComplete] = useState(false);
  const [options, setOptions] = useState({
    user: false,
    tag8Support: false,
  });
  const [preJuly2024TagInitials, setPreJuly2024TagInitials] = useState([]);
  const [validityNumber, setValidityNumber] = useState("");
  const [validityUnit, setValidityUnit] = useState("");
  const [validityToDate, setValidityToDate] = useState("");
  const [existingTagInitials, setExistingTagInitials] = useState([]);
  const [errorMessageinital, setErrorMessageinital] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarTimer, setSnackbarTimer] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editingTag, setEditingTag] = useState(null);
  const loaderContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const loaderStyle = {
    width: "100px",
    height: "100px",
  };
  const handleNewTagGeneration = async () => {
    try {
      // console.log(count, numOfDigits, typeof count, "counttt");
      // console.log(count, numOfDigits, typeof count, "counttt");
      const response = await axios.post(`${BASE_URL}/api/genratetags`, {
        count: parseInt(count, 10),
        numOfDigits,
      });

      if (response.status === 200) {
        // Handle successful response
        // console.log("Tags generated successfully");
        toast.success(response.data.message, {
          autoClose: 6000,
        });
        setCount(0);
        // setNumOfDigits("");
      }
    } catch (error) {
      // Handle error response
      console.error("Failed to generate tags", error);
    }

    setNewModalOpen(false);
  };

  // const openEndDateDialog = couponInfo => {
  //   if (couponInfo) {
  //     // console.log("coupon infor new",couponInfo);
  //     const endDate = moment(new Date(couponInfo.end_date));
  //     setDtpEndDate(endDate);
  //     // console.log("Seto",dtpEndDate);
  //     // console.log("Setdb",endDate);
  //     setSelectedCouponInfo(couponInfo);
  //     setIsEndDateDialogOpen(true);
  //   } else {
  //     toast.warn("Something went wrong!!! Please try after sometimes.");
  //   }
  // };
  useEffect(() => {
    // console.log(tagnumber, "tagggggsgsgsg");
    const delay = 3000;
    const timer = setTimeout(() => {
      setTimerComplete(true);
    }, delay);

    // Clear the timeout if the component unmounts or the dependency changes
    return () => clearTimeout(timer);
  }, [tagnumber]);
  const updateTag = (updatedTag) => {
    // console.log(tags, "settag");
    const index = tags.findIndex((tag) => tag.id === updatedTag.id);

    if (index !== -1) {
      const updatedTags = [...tags];
      updatedTags[index] = updatedTag;
      setTags(updatedTags);
    }
  };
  const handleGenerateTagNumber = async () => {
    try {
      setLoading(true); // Show loader
      let response;

      if (editingTag) {
        let response = await axios.put(
          `${BASE_URL}/api/updateTagNumber/`,
          {
            productCategory,
            tag_count: count,
            valuableType,
            companyName,
            numOfDigits,
            tagInitial,
            updatepossibility: editingTag.possibility,
            options,
            validityNumber,
            validityUnit,
            validityToDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.message) {
          toast.success(response.data.message, {
            autoClose: 6000,
          });
        }
        if (response.data.message.includes("Successfully Updated")) {
          toast.success(response.data.message, {
            autoClose: 6000,
          });
          // console.log(response.data.tagGenerationId, "tagGenerationId");
          axios
            .get(`${BASE_URL}/api/fetch-data`, {
              params: {
                tag_generation_id:
                  response.data.tagGenerationId || tagGeneration,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              const data = response.data;

              if (data.length > 0) {
                const workbook = XLSX.utils.book_new();
                const worksheetData = [];

                data.forEach((item) => {
                  const url = `https://dashboard.tag8.in/#/scan/${item.tag_number}`;

                  const excelData = {
                    create_date: item.create_date,
                    product_category: item.product_category,
                    tag_count: 1,
                    tag_initial: item.tag_initial,
                    tag_number: item.tag_number,
                    url: url,
                    valuable_type: item.valuable_type,
                    company_name: item.company_name,
                  };

                  worksheetData.push(excelData);
                });

                const worksheet = XLSX.utils.json_to_sheet(worksheetData);
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                XLSX.writeFile(workbook, "excel_file.xlsx");
              } else {
                console.log("No data received from the server");
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error("No Tags Generated", {
            autoClose: 6000,
          });
        }
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        response = await axios.post(
          `${BASE_URL}/api/generateTagNumber`,
          {
            tagInitial,
            productCategory,
            count,
            numOfDigits,
            valuableType,
            companyName,
            options,
            validityNumber,
            validityUnit,
            validityToDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (
        response.data.message.includes("Successfully processed") ||
        response.data.message.includes("Successfully Updated")
      ) {
        toast.success(response.data.message, {
          autoClose: 6000,
        });
        axios
          .get(`${BASE_URL}/api/fetch-data`, {
            params: {
              tag_generation_id: response.data.tagGenerationId || tagGeneration,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const data = response.data;

            if (data.length > 0) {
              const workbook = XLSX.utils.book_new();
              const worksheetData = [];

              data.forEach((item) => {
                const url = `https://dashboard.tag8.in/#/scan/${item.tag_number}`;

                const excelData = {
                  create_date: item.create_date,
                  product_category: item.product_category,
                  tag_count: 1,
                  tag_initial: item.tag_initial,
                  tag_number: item.tag_number,
                  url: url,
                  valuable_type: item.valuable_type,
                  company_name: item.company_name,
                };

                worksheetData.push(excelData);
              });

              const worksheet = XLSX.utils.json_to_sheet(worksheetData);
              XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

              XLSX.writeFile(workbook, "excel_file.xlsx");
            } else {
              console.log("No data received from the server");
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("No Tags Generated", {
          autoClose: 6000,
        });
      }

      if (editingTag) {
        const updatedTag = response.data.updatedTag;

        updateTag(updatedTag);
      } else {
        const tagNumbers = response.data.tagNumbers;
        setPossibility(response.data.totalCombinations);
        setTagNumber(tagNumbers);
        fetchData();
      }

      const delay = 3000;
      setTimeout(() => {
        setModalOpenState(false);
        setEditingTag(null);
        setTagInitial("");
        setProductCategory("");
        setCount(0);
        setValuableType("");
        setCompanyName("");
        setNumOfDigits(4);
        setPossibility("");
        setValidityNumber(0);
        setValidityUnit("Days");
        setValidityToDate("");
      }, delay);
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 6000,
      });
    } finally {
      setLoading(false); // Hide loader
    }
  };
  const handleTagInitialChange = (e) => {
    const inputValue = e.target.value.toUpperCase().slice(0, 3);
    setTagInitial(inputValue);

    if (inputValue === "") {
      setErrorMessageinital("");
    } else if (existingTagInitials.includes(inputValue)) {
      setErrorMessageinital("Tag Initial already exists");
    } else {
      setErrorMessageinital("");
    }
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleEdit = (row) => {
    setEditingTag(row);
    setModalOpenState(true);

    setTagInitial(row.tag_initial || "");
    setProductCategory(row.product_category || "");
    setCount(row.tag_count || 0);
    setValuableType(row.valuable_type || "");
    setCompanyName(row.company_name || "");
    setTagGeneration(row.tag_generation_id || "");
    // console.log(row.Num_of_digits);
    if (row.Num_of_digits) {
      setNumOfDigits(row.Num_of_digits);
      setIsNumOfDigitsDisabled(true);
    } else {
      setNumOfDigits(4);
      setIsNumOfDigitsDisabled(false);
    }
    setOptions({
      user: row.user_support ?? false,
      tag8Support: row.tag8_support ?? false,
    });
    setValidityNumber(row.validity_from_number || 0);
    setValidityUnit(row.validity_from_unit || "");
    const formattedValidityStart = row.validity_start
      ? new Date(row.validity_start).toISOString().split("T")[0]
      : "";
    setValidityToDate(formattedValidityStart);
    // setTagNumber(row.tag_number || "");  // Uncomment and adjust if needed
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const addCompany = () => {
    if (companyName && !companies.includes(companyName)) {
      setCompanies([...companies, companyName]);
      setCompanyName("");
    }
  };
  const handleOptionChange = (option) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };
  const selectCompany = (selectedCompany) => {
    setCompanyName(selectedCompany);
    setDropdownOpen(false);
  };
  // const handleDelete = async (product_category, tag_initial, valuable_type) => {
  //   console.log(product_category, tag_initial, "Inidiidhdhh");
  //   try {
  //     const response = await axios.delete(
  //       "http://localhost:3333/api/deleteTag",
  //       {
  //         data: { product_category, tag_initial, valuable_type },
  //       }
  //     );
  //     toast.success(response.data.message, {
  //       autoClose: 6000,
  //     });
  //     window.location.reload();
  //     // console.log(response.data.message);
  //   } catch (error) {
  //     console.error("Error deleting tag data:", error);
  //     // Handle the error, e.g., display an error message to the user
  //   }
  // };
  const handleNumOfDigitsChange = (e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      setNumError("Please enter a valid positive number.");
    } else {
      const intValue = parseInt(value, 10);
      if (intValue > 9) {
        setNumError("Please enter a single digit.");
      } else if (
        ["QR Bag", "E-Bag", "Dolphin Smart Bag"].includes(productCategory) &&
        intValue > 4
      ) {
        setNumError(
          "For the selected product category, digits cannot exceed 4."
        );
      } else {
        setNumError("");
        // console.log(typeof value, "valuee");
        setNumOfDigits(intValue);
      }
    }
  };

  const handleDownloadExcel = (row) => {
    setLoading(true);
    generateAndDownloadExcel(row);
  };

  const generateAndDownloadExcel = async (rowData) => {
    const fetchData = async (params) => {
      try {
        const response = await axios.get(`${BASE_URL}/api/fetch-data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    };

    try {
      let params = {};
      if (rowData.tag_generation_id) {
        params.tag_generation_id = rowData.tag_generation_id;
      } else {
        params = {
          company_name: rowData.company_name || null,
          create_date: rowData.create_date || null,
          product_category: rowData.product_category || null,
          tag_initial: rowData.tag_initial || null,
          valuable_type: rowData.valuable_type || null,
          count: rowData.count || null,
        };
      }

      const allData = await fetchData(params);

      if (allData.length > 0) {
        const workbook = XLSX.utils.book_new();
        const worksheetData = allData.map((item) => ({
          create_date: item.create_date,
          product_category: item.product_category,
          tag_count: 1,
          tag_initial: item.tag_initial,
          tag_number: item.tag_number,
          valuable_type: item.valuable_type,
          company_name: item.company_name,
        }));

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        XLSX.writeFile(workbook, "excel_file.xlsx");
      } else {
        // console.log("No data received from the server");
      }
    } catch (error) {
      console.error("Error generating Excel file:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const config = {
        method: "get",
        url: `${BASE_URL}/api/tagcounts`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tagInitial,
          productCategory,
          count,
          valuableType,
          numOfDigits,
          companyName,
          updatepossiblity,
        },
      };
      const response = await axios(config);
      // console.log(response.data, "API response");
      if (response.data) {
        const initials = response.data.map((item) => item.tag_initial);
        setExistingTagInitials(initials);
        console.log(response.data.create_date, "date");
        const preJuly2024 = response.data
          .filter((item) => new Date(item.create_date) < new Date("2024-07-01"))
          .map((item) => item.tag_initial);
        setPreJuly2024TagInitials(preJuly2024);
      }
      // Aggregate data
      const aggregatedData = response.data.reduce((acc, item) => {
        const productCategory = item.product_category || "";
        const companyName = item.company_name || "";
        const valuableType = item.valuable_type || "";
        const tagGenerationId = item.tag_generation_id || "";
        const key = `${item.tag_initial}-${productCategory}-${companyName}-${valuableType}-${tagGenerationId}`;

        if (!acc[key]) {
          acc[key] = { ...item, count: 0 };
        }

        acc[key].count += item.count;
        return acc;
      }, {});

      // console.log(aggregatedData);

      // Convert aggregated data object back to array
      const aggregatedArray = Object.values(aggregatedData);
      // console.log(aggregatedArray, "aggregatedArray");
      setTableData(aggregatedArray);
      setFilteredData(aggregatedArray);

      let totalPossibility = 0;
      response.data.forEach((item) => {
        if (item.possibility !== undefined) {
          const tagId = item.tag_generation_id;
          setupdatePossibility((prevState) => ({
            ...prevState,
            [tagId]: {
              possibility: item.possibility,
            },
          }));
        } else {
          console.error(
            `No 'possibility' property found for tag: ${item.tag_number}`
          );
        }
      });

      const uniqueCompanyNames = Array.from(
        new Set(response.data.map((item) => item.company_name))
      );
      setCompanies(uniqueCompanyNames);
      if (response.data.length > 0) {
        response.data.forEach((item) => {
          if (item.Num_of_digits !== undefined) {
            const tagId = item.tag_initial;
            setNumOfDigitsMap((prevState) => ({
              ...prevState,
              [tagId]: [...(prevState[tagId] || []), item.Num_of_digits],
            }));
          } else {
            console.error(
              `No 'possibility' property found for tag: ${item.tag_number}`
            );
          }
        });
      }
    } catch (error) {
      console.error("Error loading tag numbers:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setModalOpen = (isOpen, resetValues = true) => {
    if (resetValues) {
      setTagInitial("");
      setProductCategory("");
      setCount(0);
      setValuableType("");
      setNumOfDigits(4);
    }
    setEditingTag(null);
    setModalOpenState(isOpen);
  };

  const sortedData = [...tableData].sort((a, b) => {
    return new Date(b.create_date) - new Date(a.create_date);
  });
  const columns = [
    {
      dataField: "product_category",
      text: "Product Category",
      formatter: (cell) => cell || "N/A",
    },
    {
      dataField: "count",
      text: "Tag Count",
      formatter: (cell) => cell || "N/A",
    },
    {
      dataField: "tag_initial",
      text: "Tag Initial",
      formatter: (cell) => cell || "N/A",
    },
    {
      dataField: "create_date",
      text: "Create Date",
      formatter: (cell) => {
        if (!cell) return "N/A";
        const date = new Date(cell);
        if (isNaN(date.getTime())) return "Invalid Date";
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return formattedDate;
      },
    },
    {
      dataField: "valuable_type",
      text: "Valuable Type",
      formatter: (cell) => cell || "N/A",
    },
    {
      dataField: "company_name",
      text: "Company Name",
      formatter: (cell) => cell || "N/A",
    },
    {
      dataField: "possibility",
      text: "Tags Possibility",
      formatter: (cell) => cell || "N/A",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {
                handleEdit(user);
              }}
            ></i>
          </Link>
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-file-excel-outline font-size-18"
              id="edittooltip"
              onClick={() => handleDownloadExcel(user)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const pageoptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
  };
  // console.log(sortedData, "soretedddata");
  const filterdData = sortedData.filter((item) => {
    const productCategoryExists =
      item.product_category && typeof item.product_category === "string";
    const tagInitialExists =
      item.tag_initial && typeof item.tag_initial === "string";
    const valuableTypeExists =
      item.valuable_type && typeof item.valuable_type === "string";

    const searchKeyLower = searchKey.toLowerCase();

    return (
      (productCategoryExists &&
        item.product_category.toLowerCase().includes(searchKeyLower)) ||
      (tagInitialExists &&
        item.tag_initial.toLowerCase().includes(searchKeyLower)) ||
      (valuableTypeExists &&
        item.valuable_type.toLowerCase().includes(searchKeyLower))
    );
  });

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();
  const renderTable = () => {
    if (searchKey) {
      return (
        <BootstrapTable
          keyField="tag_code"
          className="my-custom-table"
          data={filterdData}
          columns={columns}
          pagination={paginationFactory(pageoptions)}
          responsive
        />
      );
    } else {
      return (
        <BootstrapTable
          keyField="tag_code"
          className="my-custom-table"
          data={sortedData}
          columns={columns}
          pagination={paginationFactory(pageoptions)}
          responsive
        />
      );
    }
  };
  // console.log(loading, "Loadinng");
  return (
    <>
      <div>
        <Card>
          {loading && (
            <div style={loaderContainerStyle}>
              <div className="loader">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                  }
                  alt="Loading..."
                  loading="lazy"
                  style={loaderStyle}
                />
              </div>
            </div>
          )}
          <CardBody>
            <div style={{ marginTop: "7.5rem" }}>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                <Button onClick={() => setModalOpen(true)}>
                  Open Tag Generation Form
                </Button>
                <Button onClick={() => setNewModalOpen(true)}>
                  Open Generator
                </Button>
              </div>
              <div
                style={{ float: "right", marginTop: "10px" }}
                className="search-box ms-2 mb-2 d-inline-block"
              >
                <div className="position-relative">
                  <div className="input-group">
                    <div className="form-outline">
                      <input
                        type="search"
                        id="form1"
                        placeholder="Search By Product Category"
                        className="form-control"
                        name="searchkey"
                        value={searchKey}
                        onChange={(event) => setSearchKey(event.target.value)}
                      />
                    </div>
                  </div>
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <Row style={{ width: "100%", marginTop: "20px" }}>
                <Col>
                  <div className="custom-table-container">{renderTable()}</div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
            {editingTag ? "Update Tag Number" : "Generate Tag Number"}
          </ModalHeader>
          <ModalBody>
            <Container className="generation-container">
              <div className="form">
                <Label for="tagInitial">Tag Initial</Label>
                <Input
                  type="text"
                  id="tagInitial"
                  value={tagInitial}
                  onChange={handleTagInitialChange}
                  invalid={!!errorMessageinital}
                  disabled={editingTag}
                />
                {errorMessageinital && (
                  <p style={{ color: "red" }}>{errorMessageinital}</p>
                )}
                <label
                  htmlFor="productCategory"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Product Category
                </label>
                <div
                  className="custom-dropdown"
                  style={{
                    display: "inline-block",
                    position: "relative",
                    marginBottom: "18px",
                  }}
                >
                  <select
                    id="productCategory"
                    value={productCategory}
                    onChange={(e) => setProductCategory(e.target.value)}
                    disabled={editingTag}
                  >
                    <option value="">Select a product category</option>
                    <option value="QR Bag">QR Bag</option>
                    <option value="QR PET">QR PET</option>
                    <option value="QR Key">QR Key</option>
                    <option value="QR Wallet">QR Wallet</option>
                    <option value="QR Gadget">QR Gadget</option>
                    <option value="QR Passport">QR Passport</option>
                    <option value="QR Human">QR Human</option>
                    <option value="QR Medical">QR Medical</option>
                    <option value="QR Vehicle">QR Vehicle</option>
                    <option value="E-Passport">E-Passport</option>
                    <option value="E-Bag">E-Bag</option>
                    <option value="Generic">Generic</option>
                    <option value="Dolphin Smart Bag">Dolphin Smart Bag</option>
                    <option value="Dolphin Passport Tracker">
                      Dolphin Passport Tracker
                    </option>
                    <option value="Dolphin Smart Wallet">
                      Dolphin Smart Wallet
                    </option>
                    <option value="Dolphin Beacon">Dolphin Beacon</option>
                  </select>
                </div>

                <Label
                  for="numOfDigits"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Number of Digits
                </Label>
                <Input
                  type="number"
                  id="numOfDigits"
                  value={numOfDigits}
                  onChange={handleNumOfDigitsChange}
                  disabled={isNumOfDigitsDisabled}
                />
                {numError && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {numError}
                  </div>
                )}
                <Label for="valuableType">Valuable Type</Label>
                <Input
                  type="text"
                  id="valuableType"
                  value={valuableType}
                  onChange={(e) => setValuableType(e.target.value)}
                  disabled={editingTag}
                />

                <label htmlFor="companyName">Company Name</label>
                <div className="d-flex">
                  <Input
                    type="text"
                    id="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    disabled={editingTag}
                  />
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    disabled={editingTag}
                  >
                    <DropdownToggle onClick={addCompany} caret>
                      <FaPlus size={20} />
                    </DropdownToggle>
                    <DropdownMenu>
                      {companies.map((company, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => selectCompany(company)}
                          disabled={editingTag}
                        >
                          {company}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <Label for="count">Count</Label>
                <Input
                  type="number"
                  id="count"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                />
                <div className="finder-options">
                  <Label
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Finder Options
                  </Label>
                  <div>
                    <Input
                      type="checkbox"
                      id="finderUserOption"
                      name="finderOptions"
                      checked={options.user}
                      onChange={() => handleOptionChange("user")}
                      disabled={editingTag}
                    />
                    <Label for="finderUserOption" className="ml-2">
                      User
                    </Label>
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      id="tag8SupportOption"
                      name="finderOptions"
                      checked={options.tag8Support}
                      onChange={() => handleOptionChange("tag8Support")}
                      disabled={editingTag}
                    />
                    <Label for="tag8SupportOption" className="ml-2">
                      Tag8 Support
                    </Label>
                  </div>
                </div>
                <div>
                  <Label
                    for="validityFrom"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Validity From
                  </Label>
                  <Label
                    for="validityNumber"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    Validity Number
                  </Label>
                  <Input
                    type="number"
                    id="validityNumber"
                    value={validityNumber}
                    onChange={(e) => setValidityNumber(e.target.value)}
                    disabled={editingTag}
                  />

                  <Label
                    for="validityUnit"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    Validity Unit
                  </Label>
                  <Input
                    type="select"
                    id="validityUnit"
                    value={validityUnit}
                    onChange={(e) => setValidityUnit(e.target.value)}
                    disabled={editingTag}
                  >
                    <option value=""></option>
                    <option value="Days">Days</option>
                    <option value="Months">Months</option>
                    <option value="Year">Year</option>
                  </Input>
                </div>

                <Label
                  for="validityToDate"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Validity To Date
                </Label>
                <Input
                  type="date"
                  id="validityToDate"
                  value={validityToDate}
                  onChange={(e) => setValidityToDate(e.target.value)}
                  min={currentDate}
                  disabled={editingTag}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
                <Button
                  color="primary"
                  onClick={handleGenerateTagNumber}
                  className="button"
                  disabled={preJuly2024TagInitials.includes(tagInitial)}
                >
                  {editingTag ? "Update" : "Generate"}
                </Button>
                {!editingTag ? (
                  <>
                    {possiblity && (
                      <p style={{ color: "green" }}>
                        `{possiblity} number of combinations can be generated
                        for this initial `
                      </p>
                    )}
                  </>
                ) : (
                  ""
                )}
                {editingTag && (
                  <>
                    {Object.entries(updatepossiblity).map(
                      ([tagId, { possibility }]) =>
                        tagId === tagGeneration && (
                          <p
                            key={tagId}
                            style={{
                              color:
                                tagId === tagGeneration ? "green" : "black",
                            }}
                          >
                            {`${possibility} number of combinations can be generated for tag.`}
                          </p>
                        )
                    )}
                  </>
                )}
              </div>
              <ToastContainer />
            </Container>
          </ModalBody>
        </Modal>

        {/* New Modal */}
        <Modal
          isOpen={newModalOpen}
          toggle={() => setNewModalOpen(!newModalOpen)}
        >
          <ModalHeader toggle={() => setNewModalOpen(!newModalOpen)}>
            New Tag Generation
          </ModalHeader>
          <ModalBody>
            <Container className="generation-container">
              <div className="form">
                <Label for="newNumOfDigits">Number of Digits</Label>
                <Input
                  type="number"
                  id="newNumOfDigits"
                  value={numOfDigits}
                  onChange={handleNumOfDigitsChange}
                />

                <Label for="newCount">Count</Label>
                <Input
                  type="number"
                  id="newCount"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                />

                <Button
                  color="primary"
                  onClick={handleNewTagGeneration}
                  className="button"
                >
                  Generate
                </Button>
              </div>
              <ToastContainer />
            </Container>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default TagGeneration;
