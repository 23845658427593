import React, { useEffect, useState } from "react";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import { BASE_URL } from "config";
import { Button, Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Flatpickr from "react-flatpickr";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { object } from "prop-types";
import Managereturn from "pages/managereturn/Managereturn";
import axiosInstance from "Axiosinstance";

function Returnproducts() {
  const [returnproduct, setreturnproduct] = useState([]);
  const [downloadmodal, setdownloadmodal] = useState(false);
  const [editclaim, seteditclaim] = useState(false);
  const [proid, setproid] = useState({});
  const [date, setdate] = useState({});
  const [searchkey, setsearchkey] = useState("");
  const [datefilter, setdatefilter] = useState({
    from: "",
    to: "",
  });
  const [tabvalue, settabvalue] = useState(0);
  const [userrole, setuserrole] = useState("");

  const handlecsv = async e => {
    try {
      if (!e.target.files) {
        return;
      }
      let data = new FormData();
      data.append("file", e.target.files[0]);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/csvmanagereturns`,
        data: data,
      };
      axiosInstance.request(config).then(({ data }) => {});
    } catch (error) {}
  };

  const claimupdate = id => {
    seteditclaim(true);
    setproid(id);
  };
  const handelseachinput = e => {
    setsearchkey(e.target.value);
  };
  const handeldatechange = e => {
    setdate({ ...date, [e.target.name]: e.target.value });
  };
  const handeldatechange2 = e => {
    const datename = e?.target?.name;
    //console.log(datename);
    setdatefilter({ ...datefilter, [e?.target?.name]: e.target.value });

    //axios.post("/api/sortdate",{datefilter,type:"logs"})
  };
  const sortdateApi = async date => {
    try {
      //console.log(date);
      if (Object.keys(datefilter).length === 2) {
        const { data } = await axiosInstance.post(`/api/sortdate`, {
          datefilter,
          type: "returndata",
        });
        //console.log(data);
        if (data?.status) {
          setreturnproduct(data?.returndata);
        }
      }
    } catch (error) {}
  };
  const handelchange = e => {
    const updatedFormdata = { ...proid, [e.target.name]: e.target.value };
    //console.log(updatedFormdata);
    setproid(updatedFormdata);
  };
  const handeldownload = async () => {
    try {
      const { data } = await axiosInstance.post(`/api/downloadcsvreturn`, date);
      if (data === "empty") {
        alert("no data present");
      } else {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "returndata.csv";
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      alert("unexpected error occured login again ");
    }
  };
  const handelclaimupdate = async e => {
    try {
      e.preventDefault();
      const { data } = await axiosInstance.post(`/api/updateclaim`, proid);
      //console.log(data);
      window.location.reload();
    } catch (error) {
      //console.log(error);
      alert("unexpected error ocured, login again");
    }
  };

  const fetchallreturn = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/getallreturn`);
      setreturnproduct(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const checkingUserrole = () => {
    try {
      const user = localStorage.getItem("user");

      if (user !== null) {
        const userInfo = JSON.parse(user);
        setuserrole(userInfo?.role);
      }
    } catch (er) {}
  };

  useEffect(() => {
    if (!searchkey.length) {
      fetchallreturn();
      checkingUserrole();
    } else {
      const getsearchResult = setTimeout(() => {
        axiosInstance
          .post(`/api/returnsearch`, { key: searchkey })
          .then(data => {
            //console.log(data);
            setreturnproduct(data.data);
          })
          .catch(er => {
            //console.log(er);
          });
      }, 1000);
      return () => clearTimeout(getsearchResult);
    }
  }, [searchkey, datefilter]);

  const column = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "created_date",
      text: "Returned Date",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.created_date ? (
              <Link to="#" className="text-dark">
                {new Date(product.created_date).toLocaleDateString()}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "returncode",
      text: "Return Code",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.returncode ? (
              <Link to="#" className="text-dark">
                {product?.returncode}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> Nill </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "tag_number",
      text: "TagNumber",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              <p> {product.tag_number} </p>
            </Link>
          </h5>
        );
      },
    },
    {
      dataField: "returntype",
      text: "ReturnType",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              <p> {product.returntype} </p>
            </Link>
          </h5>
        );
      },
    },
    {
      dataField: "productcategory",
      text: "ProductCategory",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              <p> {product.productcategory} </p>
            </Link>
          </h5>
        );
      },
    },
    {
      dataField: "claim",
      text: "claim",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.claim ? (
              <Link to="#" className="text-dark">
                <p> {product.claim} </p>
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "claimnumber",
      text: "ClaimNumber",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.claimnumber ? (
              <Link to="#" className="text-dark">
                <p> {product.claimnumber} </p>
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> Nill </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "returntype",
      text: "EditClaim",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <Link
              className="text-success"
              onClick={() => claimupdate(product)}
              to="#"
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div className="page-content">
      <Tabs
        value={tabvalue}
        onChange={() => settabvalue(tabvalue)}
        variant="scrollable"
      >
        <Tab
          style={{ color: "black" }}
          value={0}
          onClick={() => {
            settabvalue(0), fetchallreturn();
          }}
          label="Return products"
          wrapped
        />
        <Tab
          style={{ color: "black" }}
          value={1}
          onClick={() => settabvalue(1)}
          label="Manage Return"
          wrapped
        />
      </Tabs>

      {tabvalue === 0 ? (
        <>
          <h4 style={{ marginTop: "1rem" }}>List of Return Products</h4>
          <div style={{ margin: "2rem" }}>
            <Button onClick={() => setdownloadmodal(true)} variant="contained">
              Export csv
            </Button>
          </div>
          {userrole?.length && userrole === "superadmin" && (
            <>
              <div>
                <label>Upload csv for manage return</label>
                <input
                  style={{ margin: "2rem" }}
                  type="file"
                  onChange={handlecsv}
                />
              </div>
            </>
          )}

          <Col style={{ display: "flex" }} xl={6} sm={6}>
            <input
              type="search"
              id="form1"
              placeholder="Search"
              className="form-control"
              name="searchkey"
              value={searchkey}
              onChange={e => handelseachinput(e)}
            />
          </Col>

          <div style={{ marginTop: "2rem", display: "flex" }}>
            <div style={{ marginLeft: "1rem" }}>
              <TextField
                label="Starting Date"
                variant="outlined"
                type="date"
                name="from"
                value={datefilter?.from || ""}
                onChange={handeldatechange2}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                }}
              />
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <TextField
                label="Ending Date"
                variant="outlined"
                type="date"
                name="to"
                value={datefilter?.to || ""}
                onChange={handeldatechange2}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                }}
              />
            </div>
            <div style={{ margin: "1rem" }}>
              {datefilter?.from && datefilter?.to ? (
                <Button onClick={sortdateApi} variant="outlined">
                  Search
                </Button>
              ) : null}
            </div>
          </div>

          <div
            style={{ backgroundColor: "white", marginTop: "2rem" }}
            className="table-responsive"
          >
            <BootstrapTable
              keyField="return_id"
              data={returnproduct}
              columns={column}
              pagination={paginationFactory()}
            />
          </div>
        </>
      ) : (
        <>
          <Managereturn />
        </>
      )}
      <Dialog open={downloadmodal} maxWidth={"md"}>
        <DialogTitle>Export CSV</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ margin: "3rem" }}>
                  <TextField
                    label="Starting Date"
                    variant="outlined"
                    type="date"
                    name="startingdate"
                    value={date.startingdate || ""}
                    onChange={handeldatechange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                      min: new Date(),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    label="Ending Date"
                    variant="outlined"
                    type="date"
                    name="endingdate"
                    value={date.endingdate || ""}
                    onChange={handeldatechange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  disabled={Object.keys(date).length === 2 ? false : true}
                  onClick={handeldownload}
                  style={{ margin: "2rem" }}
                  variant="contained"
                >
                  Download
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdownloadmodal(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Modal isOpen={editclaim}>
        <ModalHeader toggle={() => seteditclaim(false)} tag="h4">
          Edit Claim
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handelclaimupdate}>
            <div>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Claim
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="claim"
                value={proid.claim ? proid.claim : "no"}
                onChange={handelchange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
            {proid?.claim === "yes" ? (
              <div>
                <TextField
                  name="claimnumber"
                  value={proid.claimnumber || ""}
                  onChange={handelchange}
                  fullWidth
                  label="Claim Number"
                  id="fullWidth"
                />
              </div>
            ) : null}
            <div style={{ marginTop: "2rem", alignItems: "center" }}>
              <button type="submit" className="btn btn-success save-user">
                Update
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Returnproducts;
