import axios from "axios";
import { BASE_URL } from "config";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import { Modal, ModalHeader, ModalBody, Col, Row } from "reactstrap";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Founderdetails from "components/Founderdetails";
import axiosInstance from "Axiosinstance";
import { toast } from "react-toastify";
import MiniWidget from "../Dashboard/mini-widget-insurance";
import convertDataToExcel from "data-to-xlsx";

// Api Funciton
const fetchInsuranceTagsCountByStatus = async (company = "Tata_aig") => {
  try {
    const { data } = await axiosInstance.post(
      `/api/crm/fetchinsurancetagscountbystatus?company=${company}`
    );
    return data;
  } catch (error) {
    console.log("fetchInsuranceTagsCountByStatus::", error);
  }
};
const fetchInsuranceDownloadableData = async (year, month, company) => {
  try {
    const pageType = "Tata AIG Page";
    const { data } = await axiosInstance.post(
      `/api/getinsurancebyfilter?year=${year}&month=${month}&company=${company}&pageType=${pageType}`,
      {
        filter: "Downloadable",
      }
    );
    return data;
  } catch (error) {
    console.log("fetchInsuranceDownloadbleData::", error);
  }
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = Array.from(
  { length: new Date().getFullYear() - 2024 + 1 },
  (v, i) => 2024 + i
);
const companies = ["Tata_aig"];

function Insurancedetails() {
  const [loading, setLoading] = useState(false);
  const [insurancedetails, setinsurancedetails] = useState([]);
  const [lfmodal, setlfmodal] = useState(false);
  const [singleinsurance, setsingleinsurance] = useState({});
  const [updatevalues, setupdatevalues] = useState({});
  const [confirmproduct, setconfirmproduct] = useState({});
  const [confirmmodal, setconfirmmodal] = useState(false);
  const [searchkey, setsearchkey] = useState("");
  const [founddetails, setfounddetails] = useState({});
  const [founderdetails, setfounderdetails] = useState(false);
  const [tagnumber, settagnumber] = useState("");
  const [filter, setfilter] = useState("all");
  const [reports, setReports] = useState([
    {
      icon: "bx bx-copy-alt",
      title: "Total Tags",
      value: "0",
      badgeValue: "+ 0.2%",
      color: "#1E3A8A",
      bgColor: "#d4dfff",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Total Remaining Tags",
      value: "0",
      badgeValue: "+ 0.2%",
      color: "#60A5FA",
      bgColor: "#c4dfff",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Total Fetched Tags",
      value: "0",
      badgeValue: "+ 0.2%",
      color: "#8B5CF6",
      bgColor: "#d5c3ff",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Total Active Tags",
      value: "0",
      badgeValue: "+ 0.2%",
      color: "#10B981",
      bgColor: "#c0f5e3",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Total Deactive Tags",
      value: "0",
      badgeValue: "+ 0.2%",
      color: "#F59E0B",
      bgColor: "#ffe1ae",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Total Cancel Tags",
      value: "0",
      badgeValue: "+ 0.2%",
      color: "#EF4444",
      bgColor: "#ffd8d8",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Total Inactive Tags",
      value: "0",
      badgeValue: "+ 0.2%",
      color: "#9CA3AF",
      bgColor: "#e6e6e6",
    },
  ]);
  const [monthsArray, setMonthsArray] = useState(months);
  const [downloadInsuranceState, setDownloadInsuranceState] = useState({
    isOpenModal: false,
    year: "",
    month: "",
    company: "",
  });

  const generateerror = (err) => {
    toast.error(err, {
      position: "top-center",
    });
  };
  const generateSuccess = (msg) => {
    toast.success(msg, {
      position: "top-center",
    });
  };

  // function to handel the error and dispaly a alert
  const errorHandeling = (er) => {
    if (er?.response?.data?.authError) {
      generateerror(
        er?.response?.data?.msg?.message ||
          er?.response?.data?.msg ||
          "unexpected error ocured"
      );
    } else {
      generateerror(er?.response?.data?.message || "unexpected error ocured");
    }
  };

  const openFindermodal = (product) => {
    //console.log(product);
    setfounderdetails(true);
    settagnumber(product?.tag_number);
  };
  const handelsearch = (e) => {
    setsearchkey(e.target.value);
  };

  const handelf = (product) => {
    setlfmodal(true);
    setsingleinsurance(product);
    setupdatevalues(product);
    fetchFound(product?.tag_number);
  };

  const handelconfirm = (product) => {
    setconfirmmodal(true);
    setconfirmproduct(product);
  };
  const confirmSubmit = () => {
    axiosInstance
      .post(`/api/confirmlfstatus`, confirmproduct)
      .then(({ data }) => {
        setconfirmproduct({ _id: "" });
        setconfirmmodal(false);
        fetchallDetails();
      })
      .catch((er) => {
        setconfirmproduct({ _id: "" });
        setconfirmmodal(false);
        //console.log(er);
        errorHandeling(er);
      });
  };

  const handelchange = (e) => {
    if (
      e?.target?.name === "foundername" ||
      e?.target?.name === "founderemail" ||
      e?.target?.name === "founderphone"
    ) {
      setfounddetails({
        ...founddetails,
        [e.target.name]: e.target.value,
      });
    } else {
      const updatedFormdata = {
        ...updatevalues,
        [e.target.name]: e.target.value,
      };
      //console.log(updatedFormdata);
      setupdatevalues(updatedFormdata);
    }
  };
  const handelfupdate = (e) => {
    e.preventDefault();
    //console.log(singleinsurance);
    if (updatevalues?.lf_status) {
      axiosInstance
        .post(`/api/lostandfound`, { updatevalues, founddetails })
        .then(({ data }) => {
          //console.log(data);
          setlfmodal(false);
          setupdatevalues({ _id: "" });
          setsingleinsurance({ _id: "" });
          fetchallDetails();
        })
        .catch((er) => {
          setupdatevalues({ _id: "" });
          setsingleinsurance({ _id: "" });
          //console.log(er);
          errorHandeling(er);
        });
    }
  };

  const fetchallDetails = () => {
    axiosInstance
      .get("/api/getallinsurace")
      .then(({ data }) => {
        setinsurancedetails(data?.insurance);
        setupdatevalues({
          _id: "",
        });
      })
      .catch((er) => {
        errorHandeling(er);
      });
  };
  const fetchFound = (id) => {
    axiosInstance
      .get(`/api/fetchfoundbyid`, {
        params: {
          id: id,
        },
      })
      .then(({ data }) => {
        setfounddetails(data?.findDetails);

        //console.log(data);
      })
      .catch((er) => {
        //console.log(er);
        errorHandeling(er);
      });
  };
  const columnPreviouslyFetched = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "tag_number",
      text: "Tag Number",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.tag_number ? (
              <Link to="#" className="text-dark">
                {product.tag_number}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "company_name",
      text: "Company Name",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-capitalize text-dark ">
                  {product?.company_name}
                </Link>
              </h5>
            </>
          </div>
        );
      },
    },
    {
      dataField: "product_category",
      text: "Product Category",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-capitalize text-dark ">
                  {product?.product_category}
                </Link>
              </h5>
            </>
          </div>
        );
      },
    },
    {
      dataField: "tag_status",
      text: "Tag Status",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-capitalize text-dark ">
                  {product?.tag_status}
                </Link>
              </h5>
            </>
          </div>
        );
      },
    },
  ];
  const column = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "fullName",
      text: "Full Name",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.fullName ? (
              <Link to="#" className="text-dark">
                {product.fullName}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "tag_number",
      text: "Tag Number",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.tag_number ? (
              <Link to="#" className="text-dark">
                {product.tag_number}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "policyNumber",
      text: "Policy Number",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.policyNumber ? (
              <Link to="#" className="text-dark">
                {product.policyNumber}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.email ? (
              <Link to="#" className="text-dark">
                {product.email}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "phone",
      text: "Phone",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.phone ? (
              <Link to="#" className="text-dark">
                {product.phone}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "ticketnumber",
      text: "Company Name",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              Tata Aig
            </Link>
          </h5>
        );
      },
    },

    {
      dataField: "ticketnumber",
      text: "Ticket Number",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.ticketnumber ? (
              <Link to="#" className="text-dark">
                {product.ticketnumber}
              </Link>
            ) : null}
          </h5>
        );
      },
    },
    {
      dataField: "tag_status",
      text: "tag_status",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-capitalize text-dark ">
                  {product?.tag_status}
                </Link>
              </h5>
            </>
          </div>
        );
      },
    },

    {
      dataField: "activation_date",
      text: "Activation Date",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <h5 className="font-size-14 mb-1">
                {product?.activation_date ? (
                  <Link
                    to="#"
                    className="text-capitalize text-dark text-nowrap"
                  >
                    {product?.activation_date?.split("T")[0] +
                      " " +
                      product?.activation_date?.split("T")[1]?.slice(0, 8)}
                  </Link>
                ) : null}
              </h5>
            </>
          </div>
        );
      },
      style: { width: "10rem" },
    },

    {
      dataField: "deactivation_date",
      text: "Deactivation Date",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <h5 className="font-size-14 mb-1">
                {product?.deactivation_date ? (
                  <Link
                    to="#"
                    className="text-capitalize text-dark text-nowrap"
                  >
                    {product?.deactivation_date?.split("T")[0] +
                      " " +
                      product?.deactivation_date?.split("T")[1]?.slice(0, 8)}
                  </Link>
                ) : null}
              </h5>
            </>
          </div>
        );
      },
    },

    {
      dataField: "lf_status",
      text: "lf Status",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-capitalize text-dark ">
                  {product.lf_status}
                </Link>
              </h5>
            </>
          </div>
        );
      },
    },

    {
      dataField: "lf_status",
      text: "Confirmation Status",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3 ">
            {product?.lfstatus_confirm ? (
              <>
                <h5 className="font-size-14 mb-1">
                  <Link to="#" className="text-dark text-capitalize">
                    Confirmed
                  </Link>
                </h5>
              </>
            ) : (
              <>
                {product?.lf_status &&
                (product?.tag_status === "active" ||
                  product?.tag_status === "inactive") ? (
                  <>
                    <Button
                      style={{ textTransform: "none" }}
                      onClick={() => handelconfirm(product)}
                      size="small"
                      variant="outlined"
                    >
                      {product?.lf_status === "found"
                        ? "Found Confirm"
                        : "Lost Confirm"}
                    </Button>
                  </>
                ) : null}
              </>
            )}
          </div>
        );
      },
    },
    {
      dataField: "lf_status",
      text: "Update",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            {product?.lfstatus_confirm ||
            product?.tag_status === "deactive" ||
            product?.tag_status === "cancel" ? null : (
              <>
                <Link
                  className="text-success"
                  onClick={() => handelf(product)}
                  to="#"
                >
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                  ></i>
                </Link>
              </>
            )}
          </div>
        );
      },
    },
    {
      dataField: "ticketnumber",
      text: "",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <p className=" mb-1">
                <Link
                  onClick={() => openFindermodal(product)}
                  to="#"
                  className="text-primary text-decoration-underline"
                >
                  Finder Details
                </Link>
              </p>
            </>
          </div>
        );
      },
    },
  ];
  const [tableColumn, setTableColumn] = useState(column);
  const filterApi = async (type) => {
    try {
      const { data } = await axiosInstance.post("/api/getinsurancebyfilter", {
        filter: type,
      });
      if (data?.status) {
        setinsurancedetails(data?.insurance);
      } else {
        generateerror(data?.message);
      }
    } catch (error) {
      generateerror(error?.data?.response?.message);
    }
  };
  const handelFilter = async (e) => {
    setfilter(e?.target?.value);
    if (e?.target?.value === "all") {
      fetchallDetails();
      setTableColumn(column);
    } else {
      filterApi(e?.target?.value);
      setTableColumn(columnPreviouslyFetched);
    }
  };
  useEffect(() => {
    if (!searchkey?.length) {
      fetchallDetails();
    } else {
      const getsearchResult = setTimeout(() => {
        axiosInstance
          .post(`/api/insurancesearch`, { key: searchkey })
          .then((data) => {
            if (data?.data?.status) {
              setinsurancedetails(data?.data?.searchResult);
            } else {
              fetchallDetails();
            }
          })
          .catch((er) => {
            //console.log(er);
            fetchallDetails();
            errorHandeling(er);
          });
      }, 1000);
      return () => clearTimeout(getsearchResult);
    }
  }, [searchkey]);

  useEffect(() => {
    (async () => {
      const tagsCount = await fetchInsuranceTagsCountByStatus();
      console.log("tagsCount: ", tagsCount.data.totalTags);
      if (!tagsCount?.isError) {
        setReports([
          {
            icon: "bx bx-copy-alt",
            title: "Total Tags",
            value: tagsCount.data.totalTags,
            badgeValue: "+ 0.2%",
            color: "#1E3A8A",
            bgColor: "#d4dfff",
          },
          {
            icon: "bx bx-copy-alt",
            title: "Total Remaining Tags",
            value: tagsCount.data.totalTagsInctive,
            badgeValue: "+ 0.2%",
            color: "#60A5FA",
            bgColor: "#c4dfff",
          },
          {
            icon: "bx bx-copy-alt",
            title: "Total Fetched Tags",
            value: tagsCount.data.totalTagsIspreviouslyFetched,
            badgeValue: "+ 0.2%",
            color: "#8B5CF6",
            bgColor: "#d5c3ff",
          },
          {
            icon: "bx bx-copy-alt",
            title: "Total Active Tags",
            value: tagsCount.data.totalTagsActive,
            badgeValue: "+ 0.2%",
            color: "#10B981",
            bgColor: "#c0f5e3",
          },

          {
            icon: "bx bx-copy-alt",
            title: "Total Deactive Tags",
            value: tagsCount.data.totalTagsDeactive,
            badgeValue: "+ 0.2%",
            color: "#F59E0B",
            bgColor: "#ffe1ae",
          },
          {
            icon: "bx bx-copy-alt",
            title: "Total Cancel Tags",
            value: tagsCount.data.totalTagsCancel,
            badgeValue: "+ 0.2%",
            color: "#EF4444",
            bgColor: "#ffd8d8",
          },
          {
            icon: "bx bx-copy-alt",
            title: "Total Inactive Tags",
            value: tagsCount.data.totalTagsIspreviouslyFetchedInactive,
            badgeValue: "+ 0.2%",
            color: "#9CA3AF",
            bgColor: "#e6e6e6",
          },
        ]);
      }
    })();
  }, []);

  const changeHandleDownloadModal = () => {
    setDownloadInsuranceState((prevState) => ({
      ...prevState,
      isOpenModal: !prevState.isOpenModal,
      year: "",
      month: "",
      company: "",
    }));
  };

  const changeHandleInsurance = (event) => {
    const { name, value } = event.target;
    setDownloadInsuranceState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const submitHandleDownloadInsurance = async (event) => {
    try {
      event.preventDefault();
      const { year, month, company } = downloadInsuranceState;
      if (year && month !== undefined && company) {
        setLoading(true);
        const data = await fetchInsuranceDownloadableData(year, month, company);

        if (!data.isError) {
          convertDataToExcel({
            data: data.insurance,
            fileName: `Billing_Report_${months[month]}_${year}`,
            isBordered: true,
          });
          // console.log("data: ", data);
          setLoading(false);
          changeHandleDownloadModal();
          generateSuccess("Insurance excel sheet downloaded successfully.");
        } else {
          setLoading(false);
          generateerror(data.message);
        }
      } else {
        generateerror("Please Select All Fields!");
      }
    } catch (error) {
      console.log("submitHandleDownloadInsurance::Error:: ", error);
      generateerror(
        "Error Accured while downloading the Insurance Excel Sheet!"
      );
    }
  };

  useEffect(() => {
    const currYear = new Date();
    if (currYear.getFullYear() === downloadInsuranceState.year) {
      setMonthsArray(months.slice(0, currYear.getMonth() + 1));
    } else {
      setMonthsArray(months);
    }
  }, [downloadInsuranceState.year]);

  document.title = "Insurance List";
  return (
    <div className="page-content">
      <h1>Tata Aig Lost and Found </h1>
      {/* Insurance Cards */}
      <Row>
        <Col xl="12">
          <Row>
            <MiniWidget reports={reports} />
          </Row>
        </Col>
      </Row>

      {/* Search || Filter ||  Export CSV */}
      <Row style={{ margin: "1rem" }}>
        <Col
          sm={12}
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          {/* Search Box */}
          <div
            className=""
            style={{
              width: "80%",
              display: "flex",
              gap: "1rem",
            }}
          >
            <input
              style={{
                width: "25%",
              }}
              type="search"
              id="form1"
              placeholder="Search"
              className="form-control"
              name="searchkey"
              value={searchkey}
              onChange={(e) => handelsearch(e)}
            />

            {/* Select Tag status */}
            <FormControl
              fullWidth
              style={{
                width: "25%",
              }}
            >
              <InputLabel>Choose filter</InputLabel>
              <Select
                value={filter}
                label="Choose filter"
                onChange={handelFilter}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"lost"}>Lost</MenuItem>
                <MenuItem value={"found"}>Found</MenuItem>
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"deactive"}>Deactive</MenuItem>
                <MenuItem value={"inactive"}>Inactive</MenuItem>
                <MenuItem value={"cancel"}>Cancel</MenuItem>
                {/* <MenuItem value={"previously_fetched"}>Fetched</MenuItem> */}
              </Select>
            </FormControl>
          </div>

          {/* CSV Export Btn (open modal) */}
          <div
            className=""
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={changeHandleDownloadModal}
              style={{
                marginRight: "4px",
                backgroundColor: "#556ee6",
                color: "white",
                padding: "1rem",
              }}
            >
              Export CSV!!
            </Button>
          </div>
        </Col>
      </Row>

      {/* Table */}
      {filter === "previously_fetched" ? (
        <div
          style={{ backgroundColor: "white", marginTop: "2rem" }}
          className="table-responsive"
        >
          <BootstrapTable
            keyField="_id"
            data={insurancedetails}
            columns={columnPreviouslyFetched}
            pagination={paginationFactory()}
          />
        </div>
      ) : null}
      {filter !== "previously_fetched" ? (
        <div
          style={{ backgroundColor: "white", marginTop: "2rem" }}
          className="table-responsive"
        >
          <BootstrapTable
            keyField="_id"
            data={insurancedetails}
            columns={column}
            pagination={paginationFactory()}
          />
        </div>
      ) : null}

      <Modal isOpen={lfmodal}>
        <ModalHeader toggle={() => setlfmodal(false)} tag="h4">
          lost or found
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handelfupdate}>
            <div>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Lost or Found
              </FormLabel>

              <RadioGroup
                aria-required
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="lf_status"
                value={updatevalues?.lf_status || ""}
                onChange={handelchange}
              >
                <FormControlLabel
                  value="lost"
                  control={<Radio />}
                  label="lost"
                />
                {singleinsurance?.lf_status === "lost" ||
                singleinsurance?.lf_status === "found" ? (
                  <>
                    <FormControlLabel
                      value="found"
                      control={<Radio />}
                      label="found"
                    />
                  </>
                ) : null}
              </RadioGroup>
            </div>
            {updatevalues?.lf_status === "lost" ? (
              <div>
                <TextField
                  required
                  name="ticketnumber"
                  value={updatevalues?.ticketnumber || ""}
                  onChange={handelchange}
                  fullWidth
                  label="ticketnumber"
                  id="fullWidth"
                />
              </div>
            ) : null}

            {updatevalues?.lf_status === "found" ? (
              <div>
                <div>
                  <TextField
                    required
                    name="foundername"
                    value={founddetails?.foundername || ""}
                    onChange={handelchange}
                    fullWidth
                    label="foundername"
                    id="fullWidth"
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <TextField
                    required
                    name="founderemail"
                    value={founddetails?.founderemail || ""}
                    onChange={handelchange}
                    fullWidth
                    label="founderemail"
                    id="fullWidth"
                  />
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <TextField
                    required
                    name="founderphone"
                    value={founddetails?.founderphone || ""}
                    onChange={handelchange}
                    fullWidth
                    label="founderphone"
                    id="fullWidth"
                  />
                </div>
              </div>
            ) : null}
            <div style={{ marginTop: "2rem", alignItems: "center" }}>
              <button type="submit" className="btn btn-success save-user">
                Update
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Download Insurance Details Modal */}
      <Modal isOpen={downloadInsuranceState.isOpenModal}>
        <ModalHeader toggle={changeHandleDownloadModal} tag="h4">
          Download Insurance Details
        </ModalHeader>
        <ModalBody>
          <form onSubmit={submitHandleDownloadInsurance}>
            <div>
              <div>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={downloadInsuranceState.year}
                    label="Year"
                    name="year"
                    onChange={changeHandleInsurance}
                  >
                    {years.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={downloadInsuranceState.month}
                    label="Month"
                    name="month"
                    onChange={changeHandleInsurance}
                  >
                    {monthsArray.map((val, index) => (
                      <MenuItem key={index} value={index}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ marginTop: "1rem" }}>
                <FormControl fullWidth>
                  <InputLabel>Company</InputLabel>
                  <Select
                    value={downloadInsuranceState.company}
                    label="Company"
                    name="company"
                    onChange={changeHandleInsurance}
                  >
                    {companies.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div style={{ marginTop: "2rem", textAlign: "center" }}>
              <button
                type="submit"
                className="btn btn-success save-user"
                style={{ width: "60%" }}
              >
                Download
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Dialog open={confirmmodal} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Confirm</DialogTitle>

        <DialogContentText
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="alert-dialog-description"
        >
          Are you sure you wanted to confirm
        </DialogContentText>

        <DialogActions>
          <Button
            onClick={() => {
              setconfirmmodal(false), setconfirmproduct({ _id: "" });
            }}
          >
            Disagree
          </Button>
          <Button
            onClick={() => {
              confirmSubmit();
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {founderdetails ? (
        <Founderdetails
          tagnumber={tagnumber}
          setmodal={setfounderdetails}
          foundermodal={founderdetails}
        />
      ) : null}

      {/* Loader */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Insurancedetails;
